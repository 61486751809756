/* ==== STORE FULLSCREEN ITEM STYLESHEET ==== */
/* ==== MAIN STYLES ==== */
:root {
    --txt-title-size: 35px;
    --txt-size: 20px;
}

.hidden {
    display: none;
}

.shop-item-FS-main {
    display: flex;
    flex-direction: column;
    margin: 0;
    background-color: var(--bg-color);
    color: var(--txt-color);
    min-height: 100vh;
    min-width: 100vw;
    transition: all 0.3s ease;
}

.pdf-cont {
    position: absolute;
    width: 1080px;
    height: 900px;
    right: -1080px;
    outline: solid;
    outline-color: var(--accent-color);
    outline-width: 5px;
}

.pdf-cont-mn {
    width: 1080px;
    height: 900px;
}

.shop-item-FS {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-left: -10px;
    background-color: var(--bg-color);
    margin-top: 120px;
    transition: all 0.3s ease;
    min-width: 100vw;
}

.shop-FS-left-side {
    width: calc(40vw + 60px);
    text-align: right;
}

.shop-FS-left-side img {
    max-height: 60vh;
    max-width: 40vw;
    margin: 30px;
    margin-left: 60px;
    border-radius: 10px;
    transition: all 0.3s ease;
}

.shop-FS-left-side img:hover {
    transform: scale(1.035);
}

.shop-FS-middle-side {
    display: flex;
    flex-direction: column;
    align-self: flex-start;
    margin-left: 20px;
    text-align: left;
    height: auto;
    width: calc(100vw - (40vw + 380px));
    transition: all 0.3s ease;
}

.shop-FS-middle-side h3 {
    font-size: var(--txt-title-size);
    margin: 10px;
    text-decoration: underline;
    text-underline-position: below;
    text-decoration-color: var(--secondary-color);
    text-decoration-thickness: 4px;
    text-underline-offset: 5px 0px;
}

.shop-FS-middle-side p {
    font-size: var(--txt-size);
}

.return-button-FS {
    position: fixed;
    top: 90px;
    left: 60px;
    background-color: var(--primary-color);
    color: var(--txt-color);
    border: none;
    border-radius: 5px;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
}

.return-button-FS:hover {
    background-color: var(--accent-color);
    transform: scale(1.1);
}

.shop-FS-right-side {
    display: flex;
    flex-direction: column !important;
    justify-content: top;
    text-align: center;
    width: 380px;
}

.shop-FS-right-cont {
    display: flex;
    flex-direction: column !important;
    justify-content: top;
    align-items: center;
    margin: 20px;
    background-color: var(--bg-accent);
    color: var(--txt-color);
    border-radius: 10px;
    transition: all 0.3s ease;
    width: stretch;
}

.shop-FS-right-cont h3 {
    font-size: var(--txt-title-size);
    margin: 10px;
    margin-top: 30px;
    text-decoration: underline;
    text-underline-position: below;
    text-decoration-color: var(--secondary-color);
    text-decoration-thickness: 4px;
    text-underline-offset: 5px 0px;
}

.shop-FS-right-cont p {
    font-size: var(--txt-size);
}

.shop-FS-right-cont .FS-price {
    font-size: 4rem;
    font-weight: 800;
    border-radius: 10px;
    padding: 10px;
}

.FS-filler-price {
    margin: 10px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 25px;
    text-align: left;
}

.shop-FS-right-cont button {
    margin: 20px;
    padding: 10px;
    border: none;
    border-radius: 10px;
    background-color: var(--primary-color);
    color: var(--txt-color);
    font-size: 35px;
    width: 15vw;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
}

.shop-FS-right-cont button:hover {
    background-color: var(--accent-color);
    transform: scale(1.1);
}

/* ========= ADJUSTMENTS ========= */
@media screen and (max-width: 950px) {
    .return-button-FS {
        top: 80px;
        left: 10px;
    }

    .shop-item-FS {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
    }

    .shop-FS-middle-side {
        padding-left: 7vw;
        width: 90vw;
    }

    .shop-FS-right-side {
        display: flex;
        min-width: none;
        max-width: none;
        width: stretch; 
        justify-content: center;
        align-items: center;
        margin: 20px;
    }

    .shop-FS-right-cont {
        display: flex;
        width: stretch; 
        height: auto;
    }

    .shop-FS-left-side img {
        max-width: 75vw;
        text-align: left;
    }

    .shop-FS-left-side {
        text-align: left !important;
        align-self: left !important;
    }

    .shop-FS-right-cont button {
        width: 60vw;
    }

    :root {
        --txt-title-size: 25px;
        --txt-size: 14px;
    }
}

@media screen and (max-width:1200px) and (min-width: 950px) {
    .shop-item-FS {
        overflow-y: scroll !important;
        min-height: stretch !important;
        height: stretch !important;
        align-items: flex-start !important;
        flex-direction: row !important;
        flex-wrap: wrap !important;
        justify-content: space-between !important;
    }

    .shop-item-FS-main {
        overflow-y: scroll !important;
        min-height: auto !important;
        height: stretch !important;
    }

    .shop-FS-right-cont, .shop-FS-right-side  {
        width: stretch !important;
        max-width: none !important;
        flex-direction: column !important;
    }

    .shop-FS-middle-side {
        max-width: 40%;
    }

    .shop-FS-left-side {
        max-width: 50%;
        text-align: left;
    }

    .shop-FS-right-cont button {
        width: 60vw;
    }
}