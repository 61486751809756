/* ==== FOOTER STYLES ==== */
.fotter-cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

.fotter-cont hr {
  margin-left: 3vw;
  margin-right: 3vw;
  border: none;
  border-top: 2px solid var(--accent-color);
  width: 18vw;
}

.cp-link {
  color: var(--txt-color);
  text-decoration: none;
}