/* ======= STORE TOP BAR ======= */
/* ----- MAIN ----- */
:root {
  --margin: 20px;
}

#store-top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: calc(85vw - var(--margin) * 2);
  padding-left: 3.5vw;
  background-color: var(--bg-color);
}


/* ----- STYLES ----- */
.tab-container{
  background : transparent;
  max-width: calc(85vw - var(--margin) * 2);
  margin: 0;
  padding: 0;
  max-height: 50px;
}

ul.tabs{
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  list-style-type : none;
  line-height : 50px;
  max-height: 50px;
  overflow: hidden;
  display: inline-block;
  padding-right: 20px;
  width: calc(70vw);
}

ul.tabs > li.active{
  z-index: 2;
  background: var(--bg-accent);
}

ul.tabs > li.active:before{
  display: none;
  border-color : transparent var(--bg-accent) transparent transparent;
}


ul.tabs > li.active:after{
  display: none;
  border-color : transparent transparent transparent var(--bg-accent);
}

ul.tabs > li{
  float : right;
  margin : 5px -10px 0;
  border-top-right-radius: 25px 190px;
  border-top-left-radius: 20px 90px;
  padding : 0 30px 0 25px;
  height: 190px;
  background: var(--bg-color);
  position : relative;
  box-shadow: 0 10px 20px rgba(0,0,0,.5);
  min-width: 60vw;
}

ul.tabs > li > p{
  display: inline-block;
  min-width:100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-decoration: none;
  font-weight: 800;
  cursor: pointer;
  color: #222;
  transition: all 0.3s ease;
}

ul.tabs > li > p:hover{
  color: var(--accent-color);
  transform: scaleY(1.35) scale(1.02);
}

ul.tabs > li:before, ul.tabs > li:after{
  content : '';
  background : transparent;
  height: 40px;
  width: 15px;
  border-radius: 100%;
  border-width: 10px;
  top: 0px;
  border-style : solid;
  position : absolute;
}

ul.tabs > li:before{
  border-color : transparent var(--bg-color) transparent transparent;
  transform : rotate(48deg);
  left: -23px;
}

ul.tabs > li:after{
  border-color : transparent transparent transparent var(--bg-color);
  transform : rotate(-48deg);
  right: -17px;
}

.clearfix:before, .clearfix:after { content: ""; display: table; }
.clearfix:after { clear: both; }
.clearfix { zoom: 1; }

/* ----- MEDIA QUERIES ----- */
@media screen and (max-width: 950px){
  ul.tabs > li {
    float: right;
    min-width: 50vw;
  }
}

@media screen and (max-width: 400px){
  ul.tabs > li {
    float: right;
    min-width: 40vw;
  }
}