/* ====== CODE ===== */
  .visible {
    display: flex !important;
  }
  
  .hidden {
    display: none !important;
  }

  .container-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f8f9fa;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
    position: fixed;
    width: 100vw;
    z-index: 100;
  }

  .navbar-norm, .hamburger-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 70px;
    width: 100vw;
    background-color: var(--primary-color);
    transition: all .3s ease-in-out;
  }
  
  .navbar-norm .title-main-home {
    padding: 0;
    font-size: 1.5rem;
    text-decoration: none;
    font-weight: 400;
    color: var(--txt-color);
    margin-left: 20px;
    z-index: 3;
  }
  
  .navbar-norm ul {
    display: flex;
    align-items: center;
    list-style: none;
    float: left;
  }
  .navbar-norm ul li {
    margin: 0 10px;
  }

  .navbar-links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    left: 0;
    margin-right: auto;
  }
  
  .navbar-links ul {
    margin: 0;
    height: 70px;
  }
  
  .nav-item {
    margin-right: 10px;
  }
  
  .nav-link {
    text-decoration: none;
    color: var(--txt-color);
    padding-left: 10px;
    padding-right: 10px;
    padding-top: calc(19px + ((70px - 23px) / 2));
    padding-bottom: calc(((70px - 23px) / 2));
    font-size: 20px;
  }
  
  .dropdown {
    position: relative;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: var(--bg-color);
    min-width: 100px;
    margin-top: 10px;
    border: 1px solid var(--border-color);
  }
  
  .dropdown-content a {
    color: #333;
    padding: 10px;
    display: block;
  }
  
  .navbar-links ul {
    margin-right: 30px;
  }
  
  .nav-item:hover .dropdown-content {
    display: block;
  }
  
  .nav-item:hover .nav-link {
    background-color: var(--accent-color);
  }
  
  .nav-item:hover .dropdown-content a:hover {
    background-color: var(--border-color);
  }

  .right-side {
    right: 0;
  }

  .search-box {
    position: absolute;
    margin-right: 150px;
    right: 0;
    width: 300px;
  }

  #search-box-comp .react-search-box-input {
    border-radius: 0 !important;
  }

  .account-box {
    margin-right: 30px;
  }

  .account-button {
    display: flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
    overflow: hidden;
  }
  
  .account-button img {
    width: 50px;
    height: auto;
  }

  .signIn-button {
    display: block;
    background: none;
    border: none;
    font: inherit;
    font-size: 23px;
    color: var(--txt-color); 
    margin-right: 20px;
    text-decoration: underline;
    text-underline-position: below;
    text-decoration-color: var(--secondary-color);
    text-decoration-thickness: 0px;
    text-underline-offset: 5px 0px;
    cursor: pointer;
    transition: all 0.35s ease;
  }

  .signIn-button:active {
    background-color: var(--border-color);
  }

  .signIn-button:hover {
    color: var(--txt-color-accent);
    font-weight: 800;
  }

  .visible {
    display: block;
  }
  
  .hidden {
    display: none;
  }

  .nav-bar-logo {
    height: 60px;
  }

  /* ===== HAMBURGER MENU ===== */
  .hamburger-nav {
    display: flex;
    text-align: left !important;
    justify-content: center !important;
    z-index: 3;
    transition: all 0.3s ease;
  }

  .hamburger-nav .hamburger-icon {
    display: block;
    width: 50px;
    height: 50px;
    margin-left: 10px;
    margin-top: 10px;
    cursor: pointer;
  }

  .navbar-hamb {
    position: absolute;
    top: 65px;
    left: -100%;
    width: 200px;
    height: calc(100vh - 70px);
    border-color: var(--accent-color);
    border-width: 5px;
    border-style: solid;
    background-color: var(--bg-accent);
    transition: left 0.5s ease;
    z-index: 2;
  }
  
  .navbar-hamb.open {
    left: -5px;
  }

  .navbar-hamb ul {
    list-style: none;
    justify-content: left;
    text-align: left;
    padding-left: 0;
  }

  .nav-link-hamb {
    display: flex;
    justify-content: left;
    align-items: center;
    padding: 10px;
    text-decoration: none;
    color: var(--txt-color);
    text-align: left;
    font-size: 20px;
    width: 100%;
    height: 100%;
  }

  .nav-item-hamb {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    height: 40px;
    width: 200px;

  }

  .nav-item-hamb:hover {
    background-color: var(--accent-color);
  }


  /* ===== ADJUSTMENTS ===== */
  @media (max-width: 950px) {
    .hamburger-nav {
      display: block;
    }
  
    .navbar-norm {
      display: none;
    }
  }

  @media (min-width: 950px) {
    .hamburger-nav {
      display: none;
    }
  
    .navbar-norm {
      display: flex;
    }

    .navbar-hamb.open {
      display: none;
    }
  }