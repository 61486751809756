/* LoadingAnimation.css */
:root{
    --start-time: 1s;
}

.loading {
    position: fixed;
    width: 100px;
    height: 100px;
    position: absolute;
    top: calc(50vh + 50px);
    left: calc(50vw + 50px);
    transform: translate(-50px, -50px);
}

.dot {
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: var(--primary-color); 
    border-radius: 50%;
    animation: dot-spin 2s infinite ease-in-out both;
}

.SiteBG {
    background-color: var(--bg-color);
    width: 100vw;
    height: 100vh;
}

.dot:nth-child(1) { animation-delay: calc(var(--start-time) - 0.55s); }
.dot:nth-child(2) { animation-delay: calc(var(--start-time) - 0.4s); }
.dot:nth-child(3) { animation-delay: calc(var(--start-time) - 0.25s); }
.dot:nth-child(4) { animation-delay: calc(var(--start-time) - 0.1s); }
.dot:nth-child(5) { animation-delay: calc(var(--start-time) - 0s); }

@keyframes dot-spin {
    0% { transform: rotate(-90deg) translate(50px) rotate(90deg); }
    100% { transform: rotate(270deg) translate(50px) rotate(-270deg); }
}