/* ==== APP STYLESHEET ==== */
/* ~ -- COLOR SCHEME -- */
/*
#A79277
#D1BB9E
#EAD8C0
#FFF2E1
*/
:root {
  --accent-color: #A79277;
  --primary-color: #D1BB9E;

  --bg-color: #FFF2E1;
  --bg-accent: #EAD8C0;

  --txt-color: #000;
  --txt-color-accent: #6f6f6f;

  --border-color: #ccc;
  --bg-white: #fff;

  --shadow-color: rgba(0, 0, 0, 0.4);

  --txt-title-size: 35px;
  --txt-size: 20px;

}

/* ==== MAIN STYLES ==== */
.App {
  background-color: var(--bg-color);
  text-align: center;
  margin: 0;
  max-width: 100vw;
  overflow-x: hidden;
}

Link {
  cursor: pointer;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}

/* ==== HOME SECTION STYLES ==== */
.App {
  background-color: var(--bg-color);
}

#home {
  scroll-behavior: smooth;
}

.Swiper-cont {
  margin-left: 6vw;
  margin-right: 6vw;
}

.swiper-box {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-color);
}

.Swiper-Main .swiper-pagination-bullet {
  background-color: var(--primary-color);
}

.Swiper-Main .swiper-button-next,
.Swiper-Main .swiper-button-prev {
  color: var(--primary-color);
}

.container-home .Swiper-Main img{
  object-fit: contain;
  height: calc(100vh - 150px);
  margin: 35px;
  margin-bottom: 30px;
  margin-top: 75px;
  user-select: none;
}

.autoplay-progress {
  position: relative;
  z-index: 10;
  width: 100vw;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: left;
  font-weight: bold;
  color: var(--primary-color);
}

.autoplay-progress .progress-bar {
  --progress: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  height: 100%;
  width: calc(var(--progress) * 100vw);
  background-color: var(--accent-color); 
  opacity: 0.5; 
}

.autoplay-progress span {
  color: var(--txt-color-accent);
  font-size: 15px;
  margin-left: 10px;
  margin-top: 15px;
  font-weight: 400;
}

/* ==== ABOUT SECTION STYLES ==== */
#about {
  width: 100vw;
  margin: 0;
  scroll-behavior: smooth;
}

.container-about {
  text-align: left;
  margin-left: 9vw;
  margin-right: 9vw;
  box-shadow: 0 2px 7px 0 var(--shadow-color);
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  margin-top: 10vw;
  margin-bottom: 10vw;
  background-color: var(--bg-accent);
}

.about-text h2{
  color: var(--txt-color-accent);
  text-decoration: underline var(--accent-color) 5px;
  font-size: var(--txt-title-size);
  text-underline-offset: 3px;
}
.about-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin: 20px;
  font-size: var(--txt-size);
  line-height: 1.5;
  color: var(--txt-color);
}

.about-text h2, p {
  margin: 0;
}

/* ==== GALERIJA STYLES ==== */
.galerija-swiper {
  max-height: calc(18vh + 18vw);
  min-height: calc(18vh + 18vw);
}

.container-galerija {
  display: block;
  justify-content: center;
  align-items: center;
  --margin: 8vw;
  margin-left: var(--margin);
  margin-right: var(--margin);
}

.galerija-swiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.4s;
  background-size: cover;
  background-position: center;
}

.galerija-swiper .swiper-slide-active {
  transform: scale(1.32);
}

.galerija-swiper .swiper-pagination-bullet {
  background-color: var(--primary-color);
}

.galerija-box img {
  object-fit: contain;
  user-select: none;
  margin-top: 50px;
  max-width: 30vw;
  height: calc(13vh + 13vw);
  outline-color: var(--primary-color);
  outline-style: solid;
  outline-width: calc( .3vw + .3vh);
  transition: transform 0.35s ease-in-out;
}

.galerija-box img:hover {
  transform: scale(1.05);
}

.galerija-text {
  margin: 20px;
  font-size: var(--txt-size);
  color: var(--accent-color);
}
.galerija-text h3 {
  color: var(--txt-color-accent);
  font-size: var(--txt-title-size);
  text-decoration: underline var(--accent-color) 5px;
  text-underline-offset: 3px;
}

.galerija-main-swiper {
  height: calc(18vh + 18vw);
}

.fade-slide {
  transition: opacity 0.3s ease;
}
/* -- Swiper Select section -- */
.galerija-selct-swiper {
  width: auto;
  margin-top: 35px;
  cursor: pointer;
  margin-bottom: 10px;
}

.swiper-pagination-progressbar {
  background-color: var(--bg-accent) !important;
}

.swiper-pagination-progressbar-fill {
  background-color: var(--primary-color) !important;
}

.galerija-box-select {
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  transition: transform 0.35s;
}

.galerija-box-select img {
  object-fit: contain;
  width: 170px;
  margin-top: 10px;
  transition: transform 0.35s ease-in-out;
  outline-color: var(--primary-color);
  outline-width: calc( .3vw + .3vh);
  outline-style: solid;
  position: relative;
  background-size: cover;
  background-position: center;
}

.galerija-box-select:hover {
  transform: scale(1.05);
}

.galerija-box-select h2 {
  position: relative;
  z-index: 99;
  width: 180px;
  color: var(--txt-color);
  font-size: calc(var(--txt-size));
  margin-top: -35px;
  background: rgba(211, 211, 211, 0.5);
  margin-left: 13px;
  margin-right: 13px;
}

/* ==== SHOP REDIRECTION SECTION STYLES ==== */
.RD-Store-cont {
  --margin: 9vw; 
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--bg-accent);
  border-radius: 10px;
  box-shadow: 0 2px 7px 0 var(--shadow-color);
  margin-left: var(--margin);
  margin-right: var(--margin); 
}

.shopping-icon {
  font-size: calc(2vw + 2vh);
  margin-left: calc(0.8vw + 0.8vh);
}

.RD-right-side {
  display: flex;
  flex-direction: column;
  margin: 10px;
  text-align: left;
  justify-content: center;
  align-items: center;
}

.spletna-trgovina-cont-BP {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.RD-button {
  margin: 10px;
  margin-top: 35px;
  padding: 10px;
  border-radius: 10px;
  border: none;
  background-color: var(--primary-color);
  color: var(--txt-color-l);
  font-size: 1.75rem;
  width: 300px;
  height: 70px;
  cursor: pointer;
  transition: all 0.35s ease-in-out;
}

.RD-button:hover {
  background-color: var(--accent-color);
  transform: scale(1.07);
}

.RD-right-side h2 {
  color: var(--txt-color-accent);
  font-size: var(--txt-title-size);
  text-decoration: underline var(--accent-color) 5px;
  text-underline-offset: 3px;
}

.RD-text-mn {
  font-size: var(--txt-size);
  padding-left: 100px;
  padding-right: 100px;
}

/* ==== CONTACT SECTION STYLES ==== */
#contact {
  width: 100vw;
  margin: 0;
  scroll-behavior: smooth;
}

.container-contact {
  display: flex;
  text-align: left;
  margin-left: 9vw;
  margin-right: 9vw;
  box-shadow: 0 2px 7px 0 var(--shadow-color);
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
  margin-top: 50px;
  margin-bottom: 50px;
  background-color: var(--bg-accent);
}

.contact-text {
  margin: 20px;
  font-size: var(--txt-size);
  line-height: 1.5;
  color: var(--txt-color);
}

.contact-submition {
  margin: 20px;
  font-size: var(--txt-size);
  line-height: 1.5;
  width: 40%;
  color: var(--txt-color);
}

.contact-submition form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-submition input {
  margin: 10px;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  width: calc(14vw + 14vh);
}

.contact-submition textarea {
  margin: 10px;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 5px;
  resize: none;
  width: calc(14vw + 14vh);
  height: calc(5vw + 5vh);
}

.form-group label {
  font-size: calc(var(--txt-size) + .3rem);
  margin-left: 20px;
}

.contact-submition button {
  width: 100%;
  margin: 10px;
  padding: 10px;
  border: 1px solid var(--border-color);
  border-radius: 10px;
  background-color: var(--primary-color);
  color: var(--txt-color-l);
  font-size: 1.2rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.35s ease-in-out;
  width: 200px;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: right;
}

.contact-submition button:hover {
  background-color: var(--accent-color);
  transform: scale(1.07);
}

.fill-contact {
  width: 5px;
  margin-top: 35px;
  margin-bottom: 35px;
  background-color: var(--accent-color);
}

.contact-text h2 {
  color: var(--txt-color-accent);
  text-decoration: underline var(--accent-color) 5px;
  font-size: var(--txt-title-size);
  text-underline-offset: 3px;
}

/* ---- FOOTER ------ */
.footer-main-app .fotter-cont hr {
  width: 95vw !important;
}

/* ====== ADJUSTMENTS ====== */
@media screen and (max-width: 950px) {
  .swiper-box img {
    width: calc(30vw + 30vh) !important;
  }

  .galerija-box img {
    max-width: 60vw;
    height: calc(28vw + 28vh);
  }

  .galerija-swiper {
    max-height: calc(30vw + 30vh);
    min-height: calc(30vw + 30vh);
  }

  .RD-button {
    width: calc(22vw + 22vh);
    height: calc(5vw + 5vh);
  }

  .container-contact {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .contact-submition {
    margin: 10px;
    width: 100%;
  }

  .fill-contact {
    width: 75vw;
    height: 5px;
    margin: 0;
  }

  .contact-text h2{
    margin-top: 0;
  }

  .galerija-box-select img, .galerija-box-select h2 {
    width: 140px;
  }

  .RD-text-mn {
    padding: 0;
  }

  :root {
    --txt-title-size: 25px;
    --txt-size: 14px;
  }
}