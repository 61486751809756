.PDF-main {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--bg-color);
  height: 1080px;
  width: 1080px;
  overflow: hidden;
}

.pdf-cont {
  height: 900px;
  width: 700px;
  margin-left: -40vw;
  margin-top: 5px;
  outline: solid;
  outline-color: var(--accent-color);
  outline-width: 5px;
}

.right-side-main {
  width: 75%;
  padding: 20px;

  .right-side-cont {
    border-radius: 20px;
    background-color: var(--bg-accent);
    height: stretch;
    width: stretch;
    padding: 10px;
  }
}

/* ====== PDF RENDER SIDE ====== */
.pr-main {
  width: 100%;
  margin: 0;
  height: 100%;
  overflow: hidden;
  justify-content: left;
}

.pr-table th, .pr-table td {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.hidden {
  display: none;
}

.pr-top-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: left;
  margin-left: 10px;
  margin-right: 10px;

  .pr-top-left {
    text-align: left;
  }

  .pr-top-right {
    text-align: right;
  }
}

.small-text {
  font-size: 12px;
  margin-left: 10px;
  margin-right: 10px;
}

.pr-textT-cont {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;

  .pr-textT-right {
    display: flex;
    flex-direction: row;

    .pr-textT-rightR {
      display: flex;
      flex-direction: column;
      justify-content: right;
      text-align: left;
    }

    .pr-textT-rightL {
      display: flex;
      flex-direction: column;
      justify-content: right;
      text-align: right;
    }
  }

  h2 {
    margin-left: 20px;
  }
}

.pr-table {
  text-align: left;
  width: 100%;
  margin-left: 10px;
  margin-right: 10px;
}

.pr-vrednosti {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-left: 10px;
  margin-right: 10px;

  .pr-vr-row {
    width: 70%;
  }

  .pr-vr-filler {
    width: 30%;
  }

  .pr-vrednosti-cont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    width: 100%;
  }
}

.pr-pogoji {
  margin-left: 10px;
  margin-right: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;

  ul {
    list-style-type: none;
    padding: 0;
    margin-left: 10px;
  }
}

.pr-footer {
  font-size: larger;
  text-align: left;
  margin-left: 10px;
}

.footewr-r {
  text-align: right;
  margin-left: auto;
  margin-right: 10px;
}