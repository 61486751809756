/* ======= MAIN ======== */
.store-dashboard {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: var(--bg-color);
  margin: 0;
  padding: 0;
}

.hidden {
  display: none;
}
/* ---- BACK BUTTON ---- */
.return-button-ADM {
  position: fixed;
  top: 2.5px;
  left: 5px;
  background-color: var(--primary-color);
  color: var(--txt-color);
  border: none;
  border-radius: 5px;
  font-size: 20px;
  padding: 10px;
  cursor: pointer;
  z-index: 9999;
  transition: all 0.3s ease;
}

.return-button-ADM:hover {
  background-color: var(--accent-color);
  transform: scale(1.1);
}

/* ------ TABS ------ */
.main-cont-ADM {
  --margin: 15px;
  display: flex;
  flex-direction: column;
  margin-left: var(--margin);
  margin-right: var(--margin);
  margin-bottom: var(--margin);
  background-color: var(--bg-accent);
  width: calc(100vw - (2 * var(--margin)));
  border-radius: 20px;
  height: calc(100vh - (var(--margin) + 50px));
  transition: all 0.3s ease-in-out;
}

/* ===== AKTIVNOSTI ===== */
.aktivnosti-cont{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  transition: all 0.3s ease-in-out;
}

.akt-side {
  display: flex;
  width: stretch;
  height: 80vh;
  margin: 5px;
}

.akt-cont {
  border-width: 0 !important;
  transition: all 0.3s ease-in-out;
}

.akt-cont:hover {
  transform: scale(1.025);
  border-color: var(--primary-color) !important;
  border-width: 4px !important;
  border-style: solid !important;
}
/* ----- LEFT TAB ----- */
.akt-left-side {
  display: flex;
  flex-direction: column;
}

.akt-left-top-side {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 3px 3px 3px 3px var(--shadow-color);
  margin: 10px;
  width: stretch;
  height: stretch;
  justify-content: center;
  align-items: center;
}

.akt-left-btm-side {
  display: flex;
  flex-direction: row;
  width: stretch;
  height: stretch;
}

.left-btm-cont {
  display: flex;
  border-radius: 20px;
  box-shadow: 3px 3px 3px 3px var(--shadow-color);
  margin: 10px;
  width: stretch;
  height: stretch;
  justify-content: center;
  align-items: center;
}

.right-btm-cont {
  display: flex;
  border-radius: 20px;
  box-shadow: 3px 3px 3px 3px var(--shadow-color);
  margin: 10px;
  width: stretch;
  height: stretch;
  justify-content: center;
  align-items: center;
}

/* ----- RIGHT TAB ----- */
.akt-right-cont {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  box-shadow: 3px 3px 3px 3px var(--shadow-color);
  margin: 10px;
  width: stretch;
  justify-content: center;
  align-items: center;
}

/* ----- FOOTER ----- */
.footer-main-adm .fotter-cont hr {
  width: 95vw !important;
}

/* ======= SHOP STYLES ======== */
.online-store-cont {
  display: flex;
  overflow-y: scroll;
  justify-content: center;
  max-height: 80vh;
  border-width: 0;
  border-style: solid;
  z-index: -1;
}

.shop-items-cont-ADM {
  display: flex;
  height: auto;

  -ms-overflow-style: none; 
  scrollbar-width: none; 
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 10px;
  border-width: 0;
  border-style: solid;
  transition: all 0.3s ease-in-out;
}

.shop-items-cont-ADM::-webkit-scrollbar {
  display: none;
}

/* ===== SHOP ITEM BUTTON ===== */
.edit-btn {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  color: var(--txt-color);
  border: none;
  border-radius: 5px;
  height: 40px;
  width: 40px;
  font-size: 30px;
  padding: 10px;
  cursor: pointer;
  right: 5px;
  top: 5px;
  transition: all 0.3s ease;
}

.edit-btn:hover {
  background-color: var(--accent-color);
  transform: scale(1.1);
}

/* ===== ANIMATIONS ===== */
@keyframes opacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

/* ====== MEDIA QUERIES ====== */
@media screen and (max-width: 950px) {
  .main-cont-ADM {
    width: calc(100vw - (2 * var(--margin)));
    height: calc(100vh - (var(--margin) + 50px));
  }
}