/* ======= MAIN ======= */
.edit-page-main {
  display: flex;
  height: 100vh;
  width: 100vw;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  background-color: var(--bg-color);
}

.edit-page-cont {
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  background-color: var(--bg-accent);
  border-radius: 20px;
  height: calc(100vh - 60px);
  overflow-y: hidden;
  margin: 30px;
  width: stretch;
}

.edit-item-curr {
  display: flex;
  flex-direction: row;
}

/* ====== LEFT SIDE ====== */
.edit-left-side {
  display: flex;
  flex-direction: column;
  width: stretch;
  margin: 15px;
  margin-top: 60px;
}

/* -------- IMAGE INPUT -------- */
.edit-image-upload {
  margin-top: 20px;
}

.edit-image-preview {
  object-fit: contain;
  user-select: none;
  touch-action: none;
  width: calc(32vw + 32vh);
  height: calc(25vw + 25vh);
  max-height: 65vh;
}

.edit-left-side input {
  display: none;
}

.edit-image-upload-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: var(--accent-color);
  width: stretch;
  height: 50px;
}

.edit-item-select-item {
  transition:all 0.3s ease ;
}

.edit-item-select-item:hover {
  cursor: pointer;
  background-color: #6d624b !important;
}

.plus-item:hover {
  cursor: pointer;
  background-color: #6d624b !important;
  transform: scale(1.1);
}

.trash-item {
  margin-left: 10px;
  font-size: 20px;
  transition: all 0.3s ease ;
}

.trash-item:hover {
  cursor: pointer;
  background-color: #6d624b !important;
  transform: scale(1.35);
}

.eye-item {
  margin-right: 10px;
  font-size: 20px;
  transition: all 0.3s ease ;
}

.eye-item:hover {
  cursor: pointer;
  background-color: #6d624b !important;
  transform: scale(1.35);
}

.edit-item-select-item-text {
  width: stretch;
  height: stretch;
  padding: 0;
}

/* ====== RIGHT SIDE ====== */
.edit-right-side {
  display: flex;
  flex-direction: column;
  width: stretch;
  justify-content: center;
  align-items: center;
  margin: 15px;
}

.kategorije-cont-edit {
  display: flex;
  flex-direction: row;
  width: stretch;
}

.category-edit-inp {
  width: stretch;
}
/* ====== BACK BUTTON ====== */
.return-button-EP {
  display: flex !important;
  position: unset !important;
  margin-top: 30px !important;
  margin-left: 30px !important;
  height: 40px !important;
  width: 100px !important;
  align-items: center !important;
  justify-content: center !important;
}

/* ====== EDIT BAR ====== */
.edit-item-select-cont {
  display: flex;
  flex-direction: row;
}

.edit-item-select {
  display: flex;
  flex-direction: row;
  height: 60px;
  width: stretch;
  outline: var(--accent-color) 3px solid;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 25px;
  border-radius: 20px;
}

/* ====== DEFAULT VALUES ====== */
.def-edit-main {
  display: flex;
  flex-direction: column;
  width: stretch;
  padding: 20px;
}

.def-edit-r {
  display: flex;
  flex-direction: row;
  width: stretch;
  height: auto;
  padding: 10px;
}

/* ========== ADJUSTMENTS =========== */
@media screen and (max-width: 950px) {
  .edit-page-cont {
    overflow-y: scroll;
  }

  .edit-main-cont-left-rt {
    height: stretch;
    flex-direction: column;
  }

  .edit-image-preview {
    height: calc(35vw + 35vh);
  }
}