.signBG {
    position: fixed;
    background-color: var(--bg-color);
    height: 100vh;
    width: 100vw;
}

.return-button-SN {
    position: fixed;
    top: 30px;
    left: 30px;
    background-color: var(--primary-color);
    color: var(--txt-color);
    border: none;
    border-radius: 5px;
    font-size: 20px;
    padding: 10px;
    cursor: pointer;
    z-index: 9999;
    transition: all 0.3s ease;
}

.return-button-SN:hover {
    background-color: var(--accent-color);
    transform: scale(1.1);
}