/* ! ==== STORE STYLESHEET ==== */
/* ==== MAIN STYLES ==== */
.Store {
  display: block;
  text-align: center;
  margin: 0;
}

.navbar {
  width: 100vw;
}

#StoreContents {
  display: flex;
  width: 100vw;
  /* margin-top: 86px; */
}

.left-side {
  width: 20vw;
  margin: 0;
}

.right-side {
  margin: 0;
}

.visible {
  display: block;
}

.hidden {
  display: none;
}

/* ==== LEFT SIDE STYLES ==== */
.left-container{
  position: fixed;
  display: block;
  margin: 15px;
  width: calc(20vw - 30px);
  background-color: var(--bg-color);
  height: calc(100vh - 100px - 160px);
}

.category {
  display: flex;
  text-align: left;
  align-items: center;
  height: 30px;
  margin-left: 10px;
}

.input-boxes {
  display: flex; 
  justify-content: space-between;
}

.min-input {
  margin: 0 20px 20px 20px;
  width: calc((20vw - 200px) / 2);
  height: 30px;
  left: 0;
}

.max-input {
  margin: 0 20px 20px 20px;
  width: calc((20vw - 200px) / 2);
  height: 30px;
  float: right;
}

.slider-box {
  width: calc(20vw - 70px);
}

/* ==== RIGHT SIDE STYLES ==== */
.right-container {
  position: fixed;
  display: block;
  margin: 15px;
  background-color: var(--bg-color);
  height: calc(90% + 10px);
  width: calc(80vw - 45px);
  overflow: auto;
}

.right-container::-webkit-scrollbar {
  display: none;
}

.right-container h1 {
  margin-top: 0;
}

.welcome {
  margin-bottom: 20px;
}

.shop-items {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap !important;
  margin-left: 40px;
}

.shop-items-inner {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap !important;
  margin-top: 70px;
}

.shop-item {
  display: block;
  align-items: center;
  text-align: left;
  margin: 20px;
  max-width: 430px;
  background-color: var(--secondary-color);
  color: var(--txt-color-l);
  transition: all 0.3s ease;
}

.shop-item:hover {
  background-color: var(--primary-color);
  transform: scale(1.05);
}

.item-description-container{
  margin: 10px;
}

.shop-item img {
  margin: 15px;
}

/* ----- custom buttons ------- */
.shop-delete-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.delete-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  color: var(--txt-color);
  border: none;
  border-radius: 5px;
  height: 60px;
  width: 60px;
  font-size: 30px;
  padding: 10px;
  cursor: pointer;
  z-index: 9999;
  transition: all 0.3s ease;
}

.delete-btn:hover {
  background-color: var(--accent-color);
  transform: scale(1.1);
}

.edit-shop-item-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--primary-color);
  color: var(--txt-color);
  border: none;
  border-radius: 5px;
  height: 60px;
  width: 60px;
  font-size: 30px;
  padding: 10px;
  cursor: pointer;
  z-index: 9999;
  transition: all 0.3s ease;
}

.edit-shop-item-btn:hover {
  background-color: var(--accent-color);
  transform: scale(1.1);
}

/* ==== TOPBAR STYLES ==== */
.top-bar {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--bg-color);
  color: var(--txt-color-d);
  margin: 15px 15px 15px 15px;
  width: calc(100vw - 30px);
}

.top-bar-container {
  display: flex;
  flex-direction: row;
  width: calc(100vw - 30px);
  padding-left: 15vw;
  background-color: var(--bg-accent);
}

.search-box-store {
  display: block;
  padding-top: calc((71.81px - 40px) / 2);
  width: 50vw !important;
  background-color: var(--bg-accent);
}

.search-box-comp {
  width: auto;
}

.welcome-h1-store {
  margin: 0;
  width: 250px;
}

.sc-gswNZR.dAZYal {
  align-items: center;
  justify-content: center;
  display: flex; 
  width: 100% !important;
}

#TopBar {
  display: flex;
  position: fixed;
  justify-content: center;
  background-color: var(--bg-accent);
}

/* ======== ADJUSTMENTS ========== */
@media screen and (max-width: 950px) {
  .left-side {
    display: none;
  }

  .right-side {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    margin-left: 0;
    margin: 10px;
  }

  .right-container {
    width: 100%;
    margin: 0;
  }

  .shop-items {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 0;
    
  }

  .shop-item {
    width: calc(27vw + 27vh) !important;
    margin: 5px !important;
    margin-top: 20px !important;
    margin-bottom: 20px !important;
    background-color: var(--primary-color);
  }

  .shop-item:hover {
    background-color: var(--accent-color);
  }

  .shop-item img {
    width: calc(23vw + 23vh) !important;
    height: auto !important;
  }

  .right-side-cont {
    width: 100vw;
    margin: 0;
  }

  .shop-items-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 0;
    margin-left: 0;
  }
}